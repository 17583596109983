import React, { Component } from 'react'
import { Link } from 'react-router-dom';
import $ from 'jquery'
const config = require('../Config/Config')
export default class NavBar extends Component {
    constructor(props) {
        super(props);
        this.state = {
            LoginUser: ""
        }
    }
    componentDidMount() {
        $.ajax({
            method: "post",
            url: config.SapService.isLoginUserUrl,
            crossDomain: true,
            xhrFields: {
                withCredentials: true
            },
            success: (data) => {
                this.setState({ LoginUser: data.Data });
            },
            error: (xhr, status, err) => {
                console.error("NavBar.componentDidMount ajax hata", status, err.toString());
            }
        });
    }
    render() {
        // const { isPublic } = this.props;
        return (
            <div>
                <nav className="navbar navbar-expand-lg navbar-dark bg-dark" >
                    <div className="navbar-brand">
                        <img src="../images/header-left-logo.png" style={{ "maxHeight": "37px" }} alt="Titiz Plastik"></img>
                    </div>
                    <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className="collapse navbar-collapse" id="navbarSupportedContent">
                        <ul className="navbar-nav mr-auto">
                            <li className="nav-item active pad1">
                                <Link className="btn btn-dark nav-link" to="/paymentInfo">
                                    <span className="fas fa-home"></span> Ödeme anasayfa
                                </Link>
                            </li>
                        </ul>
                        <div className="form-inline my-2 my-lg-0">
                            <div className="navbar-text active mr-4">
                                Merhaba {this.state.LoginUser}
                            </div>
                            {this.state.LoginUser !== ""
                                ? <Link className="btn btn-danger navbar-btn" to="/loginuser">Çıkış</Link>
                                : ""}
                        </div>
                    </div>
                </nav>

                {/* <nav className="navbar navbar-expand-lg navbar-dark bg-dark" >
                        <div className="navbar-brand">
                            <svg xmlns="http://www.w3.org/2000/svg" height="30" viewBox="0 0 92 45"><linearGradient id="a" gradientUnits="userSpaceOnUse" x1="45.483" x2="45.483" y2="45.001"><stop offset="0" stopColor="#00AEEF" /><stop offset=".212" stopColor="#0097DC" /><stop offset=".519" stopColor="#007CC5" /><stop offset=".792" stopColor="#006CB8" /><stop offset="1" stopColor="#0066B3" /></linearGradient><path fillRule="evenodd" clipRule="evenodd" fill="url(#a)" d="M0 45h45.974L90.966 0H0v45" /><path fillRule="evenodd" clipRule="evenodd" fill="#fff" d="M53.984 9H45l.03 21.13-7.823-21.137H29.45l-6.678 17.653c-.71-4.492-5.354-6.042-9.008-7.203-2.413-.775-4.974-1.915-4.948-3.175.02-1.034 1.37-1.993 4.053-1.85 1.8.097 3.39.242 6.553 1.77l3.11-5.42C19.648 9.3 15.66 8.373 12.39 8.37h-.02c-3.813 0-6.988 1.235-8.956 3.27-1.372 1.42-2.112 3.226-2.142 5.223-.05 2.748.957 4.696 3.073 6.253 1.788 1.31 4.075 2.16 6.09 2.784 2.485.77 4.515 1.44 4.49 2.866-.02.52-.216 1.006-.59 1.398-.62.64-1.57.88-2.885.906-2.537.054-4.417-.345-7.413-2.116L1.27 34.444C4.263 36.146 7.44 37 11.05 37l.812-.006c3.142-.057 5.692-.81 7.718-2.44.116-.093.22-.187.328-.282l-.34 1.752 7.58-.024 1.36-3.482c1.43.488 3.056.758 4.782.758 1.682 0 3.264-.256 4.667-.716l.948 3.44 13.6.013.033-7.938h2.894c6.995 0 11.13-3.56 11.13-9.53C66.56 11.896 62.54 9 53.984 9zM33.29 27.062c-1.045 0-2.025-.182-2.868-.502l2.836-8.955h.055l2.79 8.98c-.84.3-1.797.477-2.814.477zm21.22-5.145h-1.974V14.7h1.975c2.63 0 4.73.876 4.73 3.562-.002 2.78-2.1 3.655-4.73 3.655" /><path fill="none" d="M0 0h92v45H0z" /></svg>
                        </div>
                        <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                            <span className="navbar-toggler-icon"></span>
                        </button>
                        <div className="collapse navbar-collapse" id="navbarSupportedContent">
                            <ul className="navbar-nav mr-auto">
                                <li className="nav-item active pad1">
                                    <Link className="btn btn-dark nav-link" to="/main">
                                        <span className="fas fa-home"></span> Anasayfa
                                </Link>
                                </li>
                                <li className="nav-item active">
                                    <Link className="btn btn-dark nav-link" to="/user">
                                        <span className="fas fa-user"></span> Kullanıcı Bilgileri
                                </Link>
                                </li>
                                <li className="nav-item active">
                                    <Link className="btn btn-dark nav-link" to="/about">
                                        <span className="fas fa-info"></span> Hakkında
                                </Link>
                                </li>
                            </ul>
                            <div className="form-inline my-2 my-lg-0">
                                <Link className="btn btn-danger navbar-btn" to="/login">Çıkış</Link>
                            </div>
                        </div>
                    </nav> */}

            </div >
        )
    }
}
