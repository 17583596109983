import React, { Component } from 'react'
import Card from 'card'
import $ from 'jquery'
const config = require('../Config/Config')

export default class CardFormAk extends Component {
    constructor(props) {
        super(props);
        this.state = {
            BankInfo: {
                MerchantNo: "",
                OkUrl: "",
                FailUrl: "",
                CallBackUrl: "",
                TransactionType: "",
                StoreType: "",
                CurrencyCode: "",
                Lang: ""
            },
            PaymentInfo: {
                Id: "",
                Amount: "",
            },
            BankInstalmentList: [],
            Instalment: "",

            cvc: "",
            number: "",
            // pan: "",
            year: "0",
            month: "0",
            hashValue: "",
            rndValue: "",
            accept: false,
            name: "",
        };
    }

    componentDidMount() {
        new Card({
            form: '.divCard',
            container: '.card-wrapper',

            formSelectors: {
                nameInput: 'input[name="number57"]'
            },
            messages: {
                validDate: 'expire\ndate',
                monthYear: 'mm/yy'
            },
        });
        this.getPaymentInfo();
        this.getBankInfo();
        // this.getHash();

    }

    // getHash = () => {
    //     var isSave = false;
    //     $.ajax({
    //         async: false,
    //         timeout: 30000,
    //         method: "post",
    //         url: config.SapService.getHash,
    //         data: {
    //             instal: this.state.Instalment,
    //             amount: this.state.PaymentInfo.Amount
    //         },
    //         crossDomain: true,
    //         xhrFields: {
    //             withCredentials: true
    //         },
    //         success: (data) => {
    //             if (data.Success) {
    //                 this.setState({ hashValue: data.Data.Hash, rndValue: data.Data.Rnd })
    //                 isSave = true;
    //                 //console.log(data);
    //             }
    //         },
    //         error: (xhr, status, err) => {
    //             console.error("ajax hata", status, err.toString());
    //         },
    //         complete: (() => {
    //             //console.log("ajax complete");
    //         })
    //     });
    //     return isSave;
    // }
    getBankInfo = () => {
        $.ajax({
            async: false,
            method: "post",
            url: config.SapService.getBankInfo,
            // data: {
            //     bankId: val,
            // },
            crossDomain: true,
            xhrFields: {
                withCredentials: true
            },
            success: (data) => {
                if (data.Success) {
                    this.setState({ BankInfo: data.Data })
                }
                //console.log(data);
            },
            error: (xhr, status, err) => {
                console.error("ajax hata", status, err.toString());
            },
            complete: (() => {
                //console.log("ajax complete");
            })
        });
    }
    // updatePaymentInfo = () => {
    //     var isSave = false;

    //         $.ajax({
    //             method: "post",
    //             url: config.SapService.updateInstalmentPaymentUrl,
    //             data: {
    //                 Id: this.state.PaymentInfo.Id,
    //                 Amount: this.state.PaymentInfo.Amount,
    //                 Instalment: this.state.Instalment,
    //             },
    //             crossDomain: true,
    //             xhrFields: {
    //                 withCredentials: true
    //             },
    //             success: (data) => {
    //                 if (data.Success) {
    //                     isSave = true;
    //                 }
    //                 else {
    //                     alert("Lütfen bilgilerinizi kontrol ediniz. " + data.Message);
    //                 }
    //             },
    //             error: (xhr, status, err) => {
    //                 console.error("CardFromAK.updatePaymentInfo ajax hata", status, err.toString());
    //             },
    //             complete: (() => {
    //                 //console.log("ajax complete");
    //                 this.getHash();
    //             })
    //         });

    //     return isSave;
    // }

    getPaymentInfo = () => {
        $.ajax({
            // async: true,
            method: "post",
            url: config.SapService.getPaymentInfo,
            crossDomain: true,
            xhrFields: {
                withCredentials: true
            },
            success: (data) => {
                if (data.Success) {
                    this.setState({ PaymentInfo: data.Data })
                } else {
                    window.location.replace("/paymentInfo")
                }
                //console.log(data);
            },
            error: (xhr, status, err) => {
                console.error("ajax hata", status, err.toString());
            },
            complete: (() => {
                //console.log("ajax complete");
                this.getBankInstalmentList();
            })
        });
    }
    handleChange = (event) => {
        if ([event.target.name][0] !== "accept")
            this.setState({ [event.target.name]: event.target.value });
        else
            this.setState({ [event.target.name]: event.target.checked });

        if ([event.target.name][0] === "number") {
            var number = this.state.number.trim().replace(" ", "");
            this.setState({ pan: number });
        }

    }

    onInstalmentChange = (event) => {
        let val = event.target.value;
        let instal = "";

        let amountRow = this.state.BankInstalmentList.filter(function (event1) {
            return parseInt(event1.Id) === parseInt(val);
        });
        amountRow = amountRow[0];
        if (amountRow.Instalment !== 1)
            instal = amountRow.Instalment;

        this.setState({ Instalment: instal }, () => {

            this.setState({ PaymentInfo: { ...this.state.PaymentInfo, Amount: amountRow.AmountMaturity, AmountString: amountRow.AmountMaturityString } },
                () => {
                    // this.getHash();
                })

        });

    }
    getBankInstalmentList = () => {
        $.ajax({
            // async: false,
            method: "post",
            url: config.SapService.getBankInstalmentListForAmount,
            crossDomain: true,
            data: {
                amount: this.state.PaymentInfo.Amount
            },
            xhrFields: {
                withCredentials: true
            },
            success: (data) => {
                if (data.Success)
                    this.setState({ BankInstalmentList: data.Data });
                //console.log(data);
            },
            error: (xhr, status, err) => {
                console.error("PaymentInfo.bankInstalmentList ajax hata", status, err.toString());
            },
            complete: (() => {
                //console.log("ajax complete");
                document.getElementsByName("optradio")[0].checked = true
            })
        });
    }

    doPayment = () => {
        if (this.controlInput())
            document.getElementById("paymentForm").submit();
    }
    controlInput = () => {
        var err = "";
        if (this.state.number === "")
            err += "lütfen Kredi Kart Numarası alanını doldurunuz\n";
        else {
            if (this.state.number.trim().length !== 16)
                err += "lütfen 16 haneli Kredi Kart Numarası giriniz\n";
        }
        if (this.state.name === "")
            err += "lütfen Ad Soyad alanını doldurunuz\n";
        if (this.state.year === "0" || this.state.month === "0")
            err += "lütfen Ay Yıl alanını doldurunuz\n";
        else {
            var today = new Date();
            var yil = this.state.year;
            var ay = this.state.month;
            yil = "20" + yil;
            if (Number(yil) === today.getFullYear() && ay < today.getMonth())
                err += "lütfen Ay Yıl alanını geçerli doldurunuz\n";
        }
        if (this.state.cvc === "" || this.state.cvc.length !== 3)
            err += "lütfen CVC alanını doldurunuz\n";

        if (this.state.accept === false)
            err += "lütfen Mesafeli Satış Sözleşmesi ve Ön Bilgilendirme Formunu kabul ediniz\n";

        if (err === "") {

            return true;
        }
        else {
            alert(err);
            return false;
        }
    }

    render() {
        return (
            <div className="row form-container m-2 col-md-12">

                <div className="alert alert-info row form-container m-2 p-3 col-md-12">
                    <div className="col-md-6">
                        Tc No / Vergi No : {this.state.PaymentInfo.IdNumber}
                    </div>
                    <div className="col-md-6">
                        Cari Adı / Firma Adı : {this.state.PaymentInfo.Name}
                    </div>
                    <div className="col-md-6">
                        Eposta: {this.state.PaymentInfo.Email}
                    </div>
                    <div className="col-md-6">
                        Tel: {this.state.PaymentInfo.Phone}
                    </div>
                    <div className="col-md-6">
                        Kayıt No: {this.state.BankInfo.OrderIdPreCode + this.state.PaymentInfo.Id}
                    </div>
                    <div className="col-md-6">
                        Not: {this.state.PaymentInfo.Comment}
                    </div>
                    <div className="col-md-6">
                        <b>Ödenecek Tutar: {this.state.PaymentInfo.AmountString} </b>
                    </div>
                    <div className="col-md-6">
                        Banka: {this.state.PaymentInfo.BankName}
                    </div>
                </div>

                {/* <div className="alert alert-secondary col-md-12 p-3 m-2 mb-3" onChange={this.onInstalmentChange} > */}
                <div className="alert alert-secondary col-md-12 p-3 m-2 mb-3" onChange={this.onInstalmentChange} >
                    <div className="ml-2 mb-2">{this.state.PaymentInfo.BankName} Taksit Seçenekleri</div>
                    <table className="table table-light table-hover table-striped">
                        <thead>
                            <tr>
                                <th scope="col">Taksit Seçeneği</th>
                                <th scope="col">Aylık Ödeme</th>
                                <th scope="col">Toplam Tutar</th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.state.BankInstalmentList.filter(p => p.BankId === this.state.PaymentInfo.BankId).map(function (d, index) {
                                return (
                                    <tr key={index}>
                                        <td>
                                            <div className="custom-control custom-radio">
                                                <input type="radio" className="custom-control-input" id={"customRadio" + index} name="optradio" value={d.Id} />
                                                <label className="custom-control-label" htmlFor={"customRadio" + index} style={{ cursor: "pointer", width: "100%" }}>{d.Name}</label>
                                            </div>
                                        </td>
                                        <td className="form-check-label"> {d.InstalmentPriceString} </td>
                                        <td><b> {d.AmountMaturityString} </b> </td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </table>
                </div>

                <div className="card-wrapper col-md-6">
                    {/* <img src="../images/personal_credit_card.png" style={{ "maxHeight": "200px" }} alt="Titiz Plastik"></img> */}
                    <div className="divCard">
                    </div>
                </div>
                <div className="form-container active col-md-6">

                    <div className="cardInfoYok">
                        <div className="input-group">
                            <input onChange={this.handleChange} className="form-control mb-2" placeholder="Kredi Kartı Numarası *" type="number" name="number" maxLength="16" />
                            <span className="input-group-text text-muted mb-2">
                                <i className="fab fa-cc-visa"></i><i className="fab fa-cc-mastercard"></i>
                            </span>
                        </div>
                        <input onChange={this.handleChange} className="form-control mb-2" placeholder="Ad Soyad *" type="text" name="name" />
                        <div className="row">
                            <div className="col-6">

                                <select className="form-control col-10 mb-2" name="month" onChange={this.handleChange} value={this.state.month}>
                                    <option value="0">Ay Seçiniz</option>
                                    <option value="1">1-Ocak</option>
                                    <option value="2">2-Şubat</option>
                                    <option value="3">3-Mart</option>
                                    <option value="4">4-Nisan</option>
                                    <option value="5">5-Mayıs</option>
                                    <option value="6">6-Haziran</option>
                                    <option value="7">7-Temmuz</option>
                                    <option value="8">8-Ağustos</option>
                                    <option value="9">9-Eylül</option>
                                    <option value="10">10-Ekim</option>
                                    <option value="11">11-Kasım</option>
                                    <option value="12">12-Aralık</option>
                                </select>
                                <select className="form-control col-10 mb-2" name="year" onChange={this.handleChange} value={this.state.year}>
                                    <option value="0">Yıl Seçiniz</option>
                                    <option value="24">2024</option>
                                    <option value="25">2025</option>
                                    <option value="26">2026</option>
                                    <option value="27">2027</option>
                                    <option value="28">2028</option>
                                    <option value="29">2029</option>
                                    <option value="30">2030</option>
                                    <option value="31">2031</option>
                                    <option value="32">2032</option>
                                    <option value="33">2033</option>
                                    <option value="34">2034</option>
                                    <option value="35">2035</option>
                                    <option value="36">2036</option>
                                </select>

                                {/* <input onChange={this.handleChange} className="form-control  mb-2" placeholder="AA/YY *" type="tel" name="expiry" /> */}
                                <input onChange={this.handleChange} className="form-control col-10 mb-2" placeholder="CVC *" type="number" name="cvc" maxLength="3" />
                            </div>
                            <div className="col-6">

                                <form action={config.SapService.sendPayment} id="paymentForm" method="POST">

                                    <button type="button" className="btn btn-warning m-2 mt-4" onClick={this.doPayment}>Ödeme Yap</button>
                                    <label className="input-group-text m-2 p-1">Tutar: {this.state.PaymentInfo.AmountString}</label>
                                    <input type="hidden" name="pan" size="20" value={this.state.number} />
                                    <input type="hidden" name="cv2" size="4" value={this.state.cvc} />
                                    <input type="hidden" name="Ecom_Payment_Card_ExpDate_Year" value={this.state.year} />
                                    <input type="hidden" name="Ecom_Payment_Card_ExpDate_Month" value={this.state.month} />
                                    <input type="hidden" name="clientid" value={this.state.BankInfo.MerchantNo} />
                                    <input type="hidden" name="amount" value={this.state.PaymentInfo.Amount} />
                                    <input type="hidden" name="oid" value={this.state.BankInfo.OrderIdPreCode + this.state.PaymentInfo.Id} />
                                    <input type="hidden" name="okUrl" value={this.state.BankInfo.OkUrl} />
                                    <input type="hidden" name="failUrl" value={this.state.BankInfo.FailUrl} />
                                    <input type="hidden" name="callbackUrl" value={this.state.BankInfo.CallBackUrl} />
                                    <input type="hidden" name="rnd" value={this.state.rndValue} />
                                    <input type="hidden" name="TranType" value={this.state.BankInfo.TransactionType} />
                                    <input type="hidden" name="Instalment" value={this.state.Instalment} />
                                    <input type="hidden" name="storetype" value={this.state.BankInfo.StoreType} />
                                    <input type="hidden" name="lang" value={this.state.BankInfo.Lang} />
                                    <input type="hidden" name="currency" value={this.state.BankInfo.CurrencyCode} />

                                    <input type="hidden" name="hashAlgorithm" value="ver3" /> 
                                </form>

                            </div>
                        </div>
                    </div>
                </div>

                <div className="form-text" style={{ "margin": "2px 50px" }}>
                    <div className="custom-control custom-checkbox" >
                        <input onClick={this.handleChange} type="checkbox" name="accept" className="custom-control-input" id="defaultUnchecked" />
                        <label className="custom-control-label" htmlFor="defaultUnchecked">
                            <button className="btn btn-link p-0 text-primary" data-toggle="modal" data-target=".mesafelisatissozlesmesi">Mesafeli Satış Sözleşmesi</button> ve
                                <button className="btn btn-link p-0 text-primary" data-toggle="modal" data-target=".onbilgilendirmeformu"> Ön Bilgilendirme Formu</button> nu okudum kabul ediyorum.
                        </label>
                    </div>
                    <p style={{ "fontSize": "10px" }}>
                        Not : yıldız(*) ile işaretlenmiş bölümleri lütfen doldurunuz
                        </p>
                </div>
            </div >
        )
    }
}
