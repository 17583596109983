import React, { Component } from 'react'
import $ from 'jquery'
// import { Redirect } from 'react-router-dom';
const config = require('../Config/Config')

export default class LoginControlUser extends Component {
    // constructor(props) {
    //     super(props);
    //     this.state = { redirect: false }
    // }

    componentDidMount() {
        $.ajax({
            method: "post",
            url: config.SapService.isLoginUserUrl,
            crossDomain: true,
            xhrFields: {
                withCredentials: true
            },
            success: (data) => {
                if (!data.Success)
                   // this.setState({ redirect: true })
                     window.location.replace("/loginuser")
                //else
                // this.setState({ LoginUser: data.Data });
                //console.log(data);
            },
            error: (xhr, status, err) => {
                console.error("LoginControl.componentDidMount ajax hata", status, err.toString());
            }
        });
    }
    render() {
        return (
            <div>
                {/* {
                    this.state.redirect ?
                        <Redirect to='loginuser'></Redirect>
                        : ""
                } */}
            </div>
        )
    }
}
