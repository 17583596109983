import React, { Component } from 'react'
import Navbar from '../Component/NavBar'
import Contract from '../Component/Contract'
import Footer from '../Component/Footer'
import LoginControlUser from '../Component/LoginControlUser'

//import Card from 'card'
import $ from 'jquery'
const config = require('../Config/Config')

export default class PaymentInfo extends Component {
    constructor(props) {
        super(props);
        this.state = {
            amount: 0,
            instalment: "",
            bank: 0,
            idNo: "",
            personName: "",
            phone: "",
            email: "",
            comment: "",
            bankList: [],
            // bankInstalmentList: []
        }
    }

    componentDidMount() {
        this.clearPaymentInfo();
        this.bankInfoList();
        // this.bankInstalmentList();
    }
    bankInfoList = () => {
        $.ajax({
            // async: false,
            method: "post",
            url: config.SapService.getBankInfoList,
            crossDomain: true,
            xhrFields: {
                withCredentials: true
            },
            success: (data) => {
                if (data.Success)
                    this.setState({ bankList: data.Data });
                //console.log(data);
            },
            error: (xhr, status, err) => {
                console.error("PaymentInfo.bankInfoList ajax hata", status, err.toString());
            },
            complete: (() => {
                //console.log("ajax complete");
            })
        });
    }

    handleChange = (event) => {
        if ([event.target.name][0] !== "accept")
            this.setState({ [event.target.name]: event.target.value });
        else
            this.setState({ [event.target.name]: event.target.checked });

        // console.log([event.target.name])

        // if ([event.target.name][0] === "bank")
        //     this.setState({ showInstalment: true });
    }

    clearPaymentInfo = () => {
        $.ajax({
            async: true,
            method: "post",
            url: config.SapService.clearPaymentInfo,
            crossDomain: true,
            xhrFields: {
                withCredentials: true
            },
            success: (data) => {
                if (data.Success) {

                }
            },
            error: (xhr, status, err) => {
                console.error("PaymentInfo.clearPaymentInfo ajax hata", status, err.toString());
            },
            complete: (() => {
                //console.log("ajax complete");
            })
        });
    }

    controlInput = () => {
        var err = "";

        if (this.state.amount === "" || this.state.amount === 0)
            err += "lütfen Ödeme tutarı alanını doldurunuz\n";
        else if (this.state.amount.includes(".")) {
            err += "Lütfen ödeme tutar küsuratını ',' ile yapınız. örn: 10,5 \n";
        }
        else if (this.state.amount < 10) {
            err += "En az ödeme tutarı 10 tl dir\n";
        }
        if (this.state.bank < 1)
            err += "lütfen Banka seçiniz\n";
        if (this.state.idNo === "")
            err += "lütfen Tc No / Vergi No alanını doldurunuz\n";
        if (this.state.personName === "")
            err += "lütfen Cari Adı / Firma Adı alanını doldurunuz\n";
        if (this.state.phone === "")
            err += "lütfen Telefon alanını doldurunuz\n";
        else if (this.state.phone.length !== 10 || this.state.phone[0] === "0") {
            err += "lütfen Telefon numarasnı başında \"0\" olmadan 10 haneli yazınız \n";
        }

        if (err === "")
            return true;
        else {
            alert(err);
            return false;
        }
    }
    savePaymentInfo = () => {
        var isSave = false;
        if (this.controlInput()) {
            $.ajax({
                method: "post",
                url: config.SapService.addPaymentUrl,
                data: {
                    IdNumber: this.state.idNo,
                    Name: this.state.personName,
                    Amount: this.state.amount,
                    Instalment: this.state.Instalment,
                    Phone: this.state.phone,
                    Email: this.state.email,
                    Comment: this.state.comment,
                    BankId: this.state.bank
                },
                crossDomain: true,
                xhrFields: {
                    withCredentials: true
                },
                success: (data) => {
                    if (data.Success) {
                        this.setState({ paymentId: data.Data });
                        isSave = true;
                        window.location.href = "/paymentDo";
                    }
                    else {
                        alert("Lütfen bilgilerinizi kontrol ediniz. " + data.Message);
                    }
                },
                error: (xhr, status, err) => {
                    console.error("PaymentInfo.savePaymentInfo ajax hata", status, err.toString());
                },
                complete: (() => {
                    //console.log("ajax complete");
                })
            });
        }
        return isSave;
    }

    render() {
        return (
            <div>
                <LoginControlUser></LoginControlUser>
                <Navbar isPublic='true' ></Navbar>
                <div className="row" style={{ "maxWidth": "750px", margin: "auto" }}>
                    <div className="row form-container m-2 col-md-12">
                        <div className="col-md-6">
                            <label htmlFor="idNo">Tc No / Vergi No *</label>
                            <input onChange={this.handleChange} className="form-control mb-1" type="text" name="idNo" maxLength="11" />
                        </div>
                        <div className="col-md-6">
                            <label htmlFor="personName">Cari Adı / Firma Adı *</label>
                            <input onChange={this.handleChange} className="form-control mb-1" type="text" name="personName" />
                        </div>
                        <div className="col-md-6">
                            <label htmlFor="email">Eposta </label>
                            <div className="input-group">
                                <input onChange={this.handleChange} className="form-control mb-1" type="email" name="email" />
                                <div className="input-group-append mb-1">
                                    <span className="input-group-text fas fa-at pt-2"></span>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <label htmlFor="phone">Telefon *</label>
                            <div className="input-group">
                                <input onChange={this.handleChange} className="form-control mb-1" type="text" name="phone" />
                                <div className="input-group-append mb-1">
                                    <span className="input-group-text fas fa-phone pt-2"></span>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-12">
                            <label htmlFor="comment">Diğer Not</label>
                            <input onChange={this.handleChange} className="form-control mb-1" type="text" name="comment" />
                        </div>
                        <div className="col-md-6">
                            <label htmlFor="amount">Ödeme tutar *</label>
                            <div className="input-group">
                                <input onChange={this.handleChange} className="form-control mb-1" type="text" name="amount" />
                                <div className="input-group-append mb-1">
                                    <span className="input-group-text fas fa-lira-sign pt-2"></span>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <label htmlFor="amount">Banka Seçiniz *</label>
                            <select className="form-control mb-1" name="bank" onChange={this.handleChange} value={this.state.bank}>
                                <option value="0">Seçiniz</option>
                                {this.state.bankList.map(function (d, index) {
                                    return (
                                        <option key={index} value={d.Id}>{d.Name}</option>
                                    )
                                })}
                            </select>
                        </div>
                        
                    </div>

                    <div className="row form-container m-2 col-md-12">
                        <div className="col-md-6">
                            <p style={{ "fontSize": "10px" }}>
                                Not : yıldız(*) ile işaretlenmiş bölümleri lütfen doldurunuz
                        </p>
                        </div>
                        <div className="col-md-6">
                            <button type="submit" className="btn btn-warning col-md-6" onClick={this.savePaymentInfo}>Devam Et</button>
                        </div>
                    </div>
                </div>
                <Footer></Footer>
                <Contract></Contract>
            </div >
        )
    }
}