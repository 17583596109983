import React, { Component } from 'react'

import NavbarAdmin from '../../Component/NavBarAdmin'
import LoginControlAdmin from '../../Component/LoginControlAdmin'

import $ from 'jquery'
const config = require('../../Config/Config')

export default class Instalment extends Component {
    constructor(props) {
        super(props);
        this.state = {
            reset: 0,
            instalmentList: [],
            bankList: [],

            Process: "Ekle",
            Id: 0,
            BankId: "0",
            Name: "",
            Instalment: "",
            Maturity: ""
        }
    }
    componentDidMount() {
        this.getInstalmentList();
        this.bankInfoList();
    }
    bankInfoList = () => {
        $.ajax({
            // async: false,
            method: "post",
            url: config.SapService.getBankInfoList,
            crossDomain: true,
            xhrFields: {
                withCredentials: true
            },
            success: (data) => {
                if (data.Success)
                    this.setState({ bankList: data.Data });
                // console.log(data);
            },
            error: (xhr, status, err) => {
                console.error("PaymentInfo.bankInfoList ajax hata", status, err.toString());
            },
            complete: (() => {
                //console.log("ajax complete");
            })
        });
    }
    getInstalmentList = (e) => {
        $.ajax({
            // async: false,
            method: "post",
            url: config.SapService.getBankInstalmentList,
            crossDomain: true,
            xhrFields: {
                withCredentials: true
            },
            success: (data) => {
                // console.log(data.Data);
                if (data.Success)
                    this.setState({ instalmentList: data.Data })
            },
            error: (xhr, status, err) => {
                console.error("ajax hata", status, err.toString());
            },
            complete: (() => {

            })
        });
    }

    removeInstalment = (value) => {
        console.log(value);
        $.ajax({
            // async: false,
            method: "post",
            url: config.SapService.deleteBankInstalment,
            data: {
                id: value
            },
            crossDomain: true,
            xhrFields: {
                withCredentials: true
            },
            success: (data) => {
                if (!data.Success)
                    alert(data.Message);
                else
                    alert("Silme işlemi yapıldı.");
            },
            error: (xhr, status, err) => {
                console.error("ajax hata", status, err.toString());
            },
            complete: (() => {
                this.getInstalmentList();
            })
        });
    }
    onEditClick = (event) => {
        if (event.target.name === "butonUpdate") {
            let val = event.target.value;
            let amountRow = this.state.instalmentList.filter(function (event1) {
                return parseInt(event1.Id) === parseInt(val);
            });
            amountRow = amountRow[0];
            this.setState({
                Id: amountRow.Id,
                BankId: amountRow.BankId,
                Name: amountRow.Name,
                Instalment: amountRow.Instalment,
                Maturity: amountRow.Maturity,
                Process: "Güncelle"
            })
        } else if (event.target.name === "butonDelete") {
            let val = event.target.value;
            if (window.confirm("Taksit silinsin mi?")) {
                this.removeInstalment(val);
                this.onReset();
            }
        }
    }
    onReset = () => {
        this.setState({
            Id: 0,
            BankId: "",
            Name: "",
            Instalment: "",
            Maturity: "",
            Process: "Ekle"
        })
    }

    handleChange = (event) => {
        this.setState({ [event.target.name]: event.target.value });
    }
    onSaveInstal = () => {

        if (this.controlInput()) {
            $.ajax({
                // async: false,
                method: "post",
                url: config.SapService.addBankInstalment,
                data: {
                    Id: this.state.Id,
                    BankId: this.state.BankId,
                    Instalment: this.state.Instalment,
                    Name: this.state.Name,
                    Maturity: this.state.Maturity
                },
                crossDomain: true,
                xhrFields: {
                    withCredentials: true
                },
                success: (data) => {
                    if (!data.Success)
                        alert(data.Message);
                    else {
                        alert("Taksit işlemi yapıldı.");
                        this.onReset();
                    }
                },
                error: (xhr, status, err) => {
                    console.error("ajax hata", status, err.toString());
                },
                complete: (() => {
                    this.getInstalmentList();
                })
            });
        }
    }
    controlInput = () => {
        var err = "";
        if (this.state.BankId === "0")
            err += "lütfen banka seçiniz \n";
        if (this.state.Instalment === "")
            err += "Lütfen taksit sayısı giriniz \n";
        if (this.state.Name === "")
            err += "Lütfen taksit adı tanımlayın Örn:(Peşin, 4 Taksit, 4X) \n";

        if (this.state.Maturity === "")
            err += "Lütfen vade oranı alanını doldurunuz\n";
        else if (this.state.Maturity.includes(".")) {
            err += "Lütfen vade oranı küsuratını ',' ile yapınız. örn: 1,5 \n";
        }

        if (err === "") {

            return true;
        }
        else {
            alert(err);
            return false;
        }
    }
    render() {
        return (
            <div>
                <LoginControlAdmin></LoginControlAdmin>
                <NavbarAdmin></NavbarAdmin>

                <div className="m-3" style={{ fontSize: "14px" }}>
                    <div className="form-row mb-4">
                        <div className="col">
                            <select className="form-control mb-1" name="BankId" onChange={this.handleChange} value={this.state.BankId}>
                                <option value="0">Banka seçiniz</option>
                                {this.state.bankList.map(function (d, index) {
                                    return (
                                        <option key={index} value={d.Id}>{d.Name}</option>
                                    )
                                })}
                            </select>
                        </div>
                        <div className="col">
                            <input type="text" className="form-control" placeholder="isim 2X" name="Name" value={this.state.Name} onChange={this.handleChange} />
                        </div>
                        <div className="col">
                            <input type="number" className="form-control" placeholder="Taksit" name="Instalment" value={this.state.Instalment} onChange={this.handleChange} />
                        </div>
                        <div className="col">
                            <input type="text" className="form-control" placeholder="Vade oran" name="Maturity" value={this.state.Maturity} onChange={this.handleChange} />
                        </div>
                        <div className="col">
                            <button className="btn btn-primary" onClick={this.onSaveInstal}>{this.state.Process}</button>
                            {this.state.Process !== "Ekle" ?
                                <button className="btn btn-warning ml-1" title="İptal et" onClick={this.onReset}>+</button>
                                : ""}
                        </div>
                    </div>

                    <table id="paymentList" className="table table-sm table-striped table-bordered table-hover" >
                        <thead>
                            <tr>
                                {/* <th className="th-sm ">Id</th> */}
                                <th className="th-sm ">Banka</th>
                                <th className="th-sm ">İsim</th>
                                <th className="th-sm ">Taksit</th>
                                <th className="th-sm ">Vade Oran</th>
                                <th className="th-sm " style={{ width: "110px" }}>İşlem</th>
                            </tr>
                        </thead>
                        <tbody onClick={this.onEditClick} >
                            {this.state.instalmentList.map(function (d, index) {
                                return (
                                    <tr key={index}>
                                        {/* <td >{d.Id}</td> */}
                                        <td >{d.BankName}</td>
                                        <td >{d.Name}</td>
                                        <td> {d.Instalment}</td>
                                        <td> {d.Maturity}</td>
                                        {/* <td >{d.PaymentStatus ? <span class="fas fa fa-check"></span> : ""}</td> */}
                                        <td>
                                            <button className="btn btn-sm btn-primary fas fa-edit" title="Düzenle" name="butonUpdate" value={d.Id}></button>
                                            <button className="btn btn-sm btn-danger fas fa-trash ml-1" title="Sil" name="butonDelete" value={d.Id}></button>
                                        </td>
                                    </tr>
                                )
                            })}
                        </tbody>
                        <tfoot>
                            <tr>
                                {/* <th className="th-sm">Id</th> */}
                                <th className="th-sm">Banka</th>
                                <th className="th-sm">İsim</th>
                                <th className="th-sm">Taksit</th>
                                <th className="th-sm">Vade Oran</th>
                                <th className="th-sm">İşlem</th>
                            </tr>
                        </tfoot>
                    </table>
                </div>
            </div>
        )
    }
}