import React, { Component } from 'react'
import $ from 'jquery'
const config = require('../Config/Config')

export default class LoginControlAdmin extends Component {
    componentDidMount() {
        $.ajax({
            method: "post",
            url: config.SapService.isLoginAdminUrl,
            crossDomain: true,
            xhrFields: {
                withCredentials: true
            },
            success: (data) => {
                //console.log(data);
                if (!data.Success)
                    window.location.replace("/loginAdmin")
            },
            error: (xhr, status, err) => {
                console.error("ajax hata", status, err.toString());
            }
        });
    }
    render() {
        return (
            <div>

            </div>
        )
    }
}
