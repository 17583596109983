import React, { Component } from 'react'

export default class Footer extends Component {
    render() {
        return (
            <footer style={{"marginTop":"150px"}} className="page-footer font-small pt-4 bg-secondary text-white">
                <div className="container-fluid text-center text-md-left">
                    <div className="row">
                        <div className="col-md-6 mt-md-0">
                            <p>
                                <small>
                                 <span className="fa fa-map-marker fa-fw" aria-hidden="true"></span> Hadımköy Ömerli Mah. Adnan Kahveci Cad. No:42 PK34555 Arnavutköy / İSTANBUL
                                    <br />
                                  <span className="fa fa-phone fa-fw" aria-hidden="true"></span> <a href="tel:4442140" className="text-white">444 2 140</a>
                                    <br />
                                    <span className="fa fa-at fa-fw" aria-hidden="true"></span> <a href="mailto:finans@titizplastik.com" className="text-white">finans@titizplastik.com</a>
                                </small>
                            </p>
                        </div>
                        <div className="col-md-3 mb-md-0 mb-2">
                            <ul className="list-unstyled">
                                <li>
                                    <button type="button" className="btn btn-link text-warning" data-toggle="modal" data-target=".mesafelisatissozlesmesi">Mesafeli Satış Sözleşmesi</button>
                                </li>
                                <li>
                                    <button type="button" className="btn btn-link text-warning" data-toggle="modal" data-target=".onbilgilendirmeformu">Ön Bilgilendirme Formu</button>
                                </li>
                                <li>
                                    <button type="button" className="btn btn-link text-warning" data-toggle="modal" data-target=".gizlilikFormu">Gizlilik ve Güvenlik</button>
                                </li>
                            </ul>
                        </div>
                        <div className="col-md-3 mb-md-0 mb-3">
                            <img src="../images/visa-master.png" style={{ "maxHeight": "50px" }} alt="Visa Master"></img>
                            <img src="../images/rapidssl_ssl_certificate.gif" style={{ "maxHeight": "40px" }} alt="Secure SSL"></img>
                            {/* <ul className="list-unstyled">
                                <li>
                                    <a href="#" className="text-warning">Link 1</a>
                                </li>
                                </ul> */}
                        </div>
                    </div>
                </div>
                <div className="footer-copyright text-center py-3">© 2020 Copyright : <a href="https://www.titizplastik.com/" target="_blank" className="text-warning" rel="noopener noreferrer">Titiz Plastik Dış Ticaret ve Sanayi Limited Şirketi</a>
                </div>
            </footer>
        )
    }
}
