import React, { Component } from 'react'
import Logout from '../Component/Logout'
import $ from 'jquery'
// import { Link } from 'react-router-dom';
import ClipLoader from 'react-spinners/ClipLoader';
const config = require('../Config/Config')

export default class LoginUser extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLogin: false,
            UserName: "",
            Password: "",
            Loading: false
        }
        this.handleChange = this.handleChange.bind(this);
    }

    handleChange(event) {
        this.setState({ [event.target.name]: event.target.value });
    }

    Connect = (e) => {
        e.preventDefault();
        this.setState({ Loading: true, isLoginAtt: true });

        $.ajax({
            method: "post",
            url: config.SapService.loginUserUrl,
            data: {
                userName: this.state.UserName,
                password: this.state.Password
            },
            crossDomain: true,
            xhrFields: {
                withCredentials: true
            },
            success: (data) => {
                console.log(data);
                if (data.Success)
                    window.location.replace("/paymentInfo")
                else
                    this.setState({ isLoginAtt: false })
            },
            error: (xhr, status, err) => {
                console.error("ajax hata", status, err.toString());
            },
            complete: (() => {
                this.setState({ Loading: false });
            })
        });
    }
    render() {
        return (
            <div>
                <Logout loginUrl="login"></Logout>
                {this.state.isLoginAtt === false ?
                    <div className="alert alert-danger alert-dismissible hide fade show" role="alert">
                        <strong>Giriş başarısız!</strong> Kullanıcı adı ve şifrenizi kontrol ediniz.
                    </div>
                    : ""
                }

                <div style={{ "maxWidth": "400px", margin: "auto" }}>
                    <form className="text-center border rounded border-info p-5 m-2 md-col-12" >
                        <p className="h4 mb-4"> <span className="fas fa-sign-in-alt"></span> Yetkili girişi </p>
                        <input required value={this.state.UserName} onChange={this.handleChange} type="text" id="UserName" name="UserName" className="form-control mb-4" placeholder="Kullanıcı adı" />
                        <input required value={this.state.Password} onChange={this.handleChange} type="password" id="Password" name="Password" className="form-control mb-4" placeholder="Şifre" />

                        <ClipLoader
                            sizeUnit={"px"}
                            size={50}
                            color={'#123abc'}
                            loading={this.state.Loading}
                        />
                        {this.state.Loading ? "" :
                            <button className="btn btn-info btn-block my-4" onClick={this.Connect}>Giriş yap</button>
                        }
                        <div className="d-flex justify-content-around">
                            {/* <Link to="/remember-password">Şifremi unuttum?</Link> */}
                        </div>
                        <div className="d-flex justify-content-around mt-4">
                            <img alt="Sap Business One" src="../images/header-left-logo.png"></img>
                        </div>
                    </form>
                </div >
            </div>
        )
    }
}