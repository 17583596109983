// const param = require("../Config/Param.json");
let url = window.location.origin + "/WebApi/";
if (window.location.origin.includes("localhost")) {
    url = "http://localhost:7104/"
}
//const url = "http://localhost:7104/"
export const SapService = {
    url: url,
    loginUrl: url + "Login/Connect",
    loginUserUrl: url + "Login/LoginUser",
    logoutUrl: url + "Login/Disconnect",

    isLoginUrl: url + "Login/IsLogin", //boş olabilir
    isLoginUserUrl: url + "Login/IsLoginUser",

    loginAdminUrl: url + "Login/LoginAdmin",
    isLoginAdminUrl: url + "Login/IsLoginAdmin",

    addPaymentUrl: url + "Payment/Add",
    // updateInstalmentPaymentUrl: url + "Payment/UpdateInstalment",
    paymentList: url + "Payment/List",
    getHash: url + "Payment/GetHash",
    getPaymentInfo: url + "Payment/GetPaymentInfo",
    clearPaymentInfo: url + "Payment/ClearPaymentInfo",
    printPayment: url + "Payment/Print",

    getBankInfo: url + "BankInfo/GetBankInfo",
    getBankInfoList: url + "BankInfo/List",

    getBankInstalmentListForAmount: url + "BankInstalment/ListForAmount", //fiyta göre instalmentleri gereiyore
    getBankInstalmentList: url + "BankInstalment/List", //tüm listeki pasiflerle beraber getiriyore
    deleteBankInstalment:url +"BankInstalment/Remove",
    addBankInstalment:url +"BankInstalment/Add",
    getParam: url + "Parameter/GetParam",

    sendPayment: url+ "PaymentAk/SendPayment"

}