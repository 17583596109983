import React, { Component } from 'react'
// import { Link } from 'react-router-dom'
import Moment from 'react-moment'
import NavbarAdmin from '../../Component/NavBarAdmin'
import LoginControlAdmin from '../../Component/LoginControlAdmin'

import $ from 'jquery'
const config = require('../../Config/Config')

export default class PaymentList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            ad: "özer",
            paymentList: []
        }
    }
    componentDidMount() {
        this.List();
    }

    List = (e) => {
        $.ajax({
            // async: false,
            method: "post",
            url: config.SapService.paymentList,
            crossDomain: true,
            xhrFields: {
                withCredentials: true
            },
            success: (data) => {
                // console.log(data.Data);
                if (data.Success)
                    this.setState({ paymentList: data.Data })
            },
            error: (xhr, status, err) => {
                console.error("ajax hata", status, err.toString());
            },
            complete: (() => {

            })
        });
    }

    render() {

        return (

            <div>

                <LoginControlAdmin></LoginControlAdmin>
                <NavbarAdmin></NavbarAdmin>
                <div className="m-3" style={{ fontSize: "14px" }}>
                    <table id="paymentList" className="table table-sm table-striped table-bordered table-hover" >
                        <thead>
                            <tr>

                                <th className="th-sm">No</th>
                                <th className="th-sm">Cari/Firma</th>
                                <th className="th-sm d-none d-sm-table-cell">Banka</th>
                                <th className="th-sm d-none d-sm-table-cell">Tc/Vergi</th>
                                <th className="th-sm">Telefon</th>
                                <th className="th-sm">Email</th>
                                <th className="th-sm">Taskit</th>
                                <th className="th-sm">Tutar</th>
                                <th className="th-sm">Ödeme</th>
                                <th className="th-sm">Tarih</th>
                                <th className="th-sm d-none d-sm-table-cell">İşlem</th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.state.paymentList.map(function (d, index) {
                                return (
                                    <tr key={index}>
                                        <td>{d.Id}</td>
                                        <td title={d.Comment}>
                                            {d.Name}
                                        </td>
                                        <td className="d-none d-sm-table-cell">{d.BankName}</td>
                                        <td className="d-none d-sm-table-cell">{d.IdNumber}</td>
                                        <td >{d.Phone}</td>
                                        <td >{d.Email}</td>
                                        <td> {d.Instalment}</td>
                                        <td >{d.AmountString}</td>
                                        <td >{d.PaymentStatus ? <span className="fas fa fa-check"></span> : ""}</td>
                                        <td><Moment format="YYYY-MM-DD hh:mm" date={d.CreateDate} /></td>
                                        <td className="d-none d-sm-table-cell">
                                            {d.PaymentStatus ?
                                                <a href={config.SapService.printPayment + "?id=" + d.IdHash} target="_blank" rel="noopener noreferrer" title="Makbuz"><span className="fas fa-file-invoice-dollar fa-2x"></span></a>
                                                : ""
                                            }
                                        </td>
                                    </tr>
                                )
                            })}
                        </tbody>
                        <tfoot>
                            <tr>
                                <th className="th-sm">No</th>
                                <th className="th-sm">Cari/Firma</th>
                                <th className="th-sm d-none d-sm-table-cell">Banka</th>
                                <th className="th-sm d-none d-sm-table-cell">Tc/Vergi</th>
                                <th className="th-sm">Telefon</th>
                                <th className="th-sm">Email</th>
                                <th className="th-sm">Taksit</th>
                                <th className="th-sm">Tutar</th>
                                <th className="th-sm">Ödeme</th>
                                <th className="th-sm">Tarih</th>
                                <th className="th-sm d-none d-sm-table-cell">İşlem</th>
                            </tr>
                        </tfoot>
                    </table>
                </div>
            </div>
        )
    }
}
