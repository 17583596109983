import React, { Component } from 'react'
import LoginControlAdmin from '../../Component/LoginControlAdmin'
import NavBarAdmin from '../../Component/NavBarAdmin'
const config = require('../../Config/Config')

export default class Params extends Component {
    render() {
        return (
            <div>
                <LoginControlAdmin></LoginControlAdmin>
                <NavBarAdmin></NavBarAdmin>
                <div className="m-3">
                    <table className="table table-sm table-striped table-bordered display" >
                        <tbody>
                        <tr>
                                <td>Main Url</td>
                                <td>{config.SapService.url}</td>
                            </tr>
                            <tr>
                                <td>Login</td>
                                <td>{config.SapService.loginUrl}</td>
                            </tr>
                            <tr>
                                <td>Logout</td>
                                <td>{config.SapService.logoutUrl}</td>
                            </tr>
                            <tr>
                                <td>IsLogin</td>
                                <td>{config.SapService.isLoginAdminUrl}</td>
                            </tr>
                            <tr>
                                <td>AddPayment</td>
                                <td>{config.SapService.addPaymentUrl}</td>
                            </tr>
                            <tr>
                                <td>AdminLogin</td>
                                <td>{config.SapService.loginAdminUrl}</td>
                            </tr>
                            <tr>
                                <td>IsLoginAdmin</td>
                                <td>{config.SapService.isLoginAdminUrl}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        )
    }
}
