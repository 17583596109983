import React, { Component } from 'react'
import { Link } from 'react-router-dom';

export default class NavBarAdmin extends Component {
    render() {
        return (
            <div>

                <nav className="navbar navbar-expand-lg navbar-dark bg-dark" >
                    <div className="navbar-brand">
                        <img src="../images/header-left-logo.png" style={{ "maxHeight": "37px" }} alt="Titiz Plastik"></img>
                    </div>
                    <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className="collapse navbar-collapse" id="navbarSupportedContent">
                        <ul className="navbar-nav mr-auto">
                            <li className="nav-item active pad1">
                                <Link className="btn btn-dark nav-link" to="/paymentList">
                                    <span className="fas fa-home"></span> Ödeme listesi
                                </Link>
                            </li>
                            <li className="nav-item active pad1">
                                <Link className="btn btn-dark nav-link" to="/params">
                                    <span className="fas fa-project-diagram"></span> Parametre
                                </Link>
                            </li>
                            <li className="nav-item active pad1">
                                <Link className="btn btn-dark nav-link" to="/instalment">
                                    <span className="fa fa-credit-card"></span> Taksit Planı
                                </Link>
                            </li>
                        </ul>
                        <div className="form-inline my-2 my-lg-0">
                            <Link className="btn btn-danger navbar-btn" to="/loginAdmin">Çıkış</Link>
                        </div>
                    </div>
                </nav>


            </div >
        )
    }
}
