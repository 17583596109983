import React, { Component } from 'react'
import Navbar from '../Component/NavBar'
import Contract from '../Component/Contract'
import Footer from '../Component/Footer'
import LoginControlUser from '../Component/LoginControlUser'

import CardFormAk from '../Component/CardFormAk'
// import CardFormGaranti from '../Component/CardFormGaranti'

// import $ from 'jquery'
// const config = require('../Config/Config')

export default class PaymentDo extends Component {

    // constructor(props) {
    //     super(props);
    //     // this.state = {
    //     //     // accept: false,
    //     //     amount: "",
    //     //     bank: 0,
    //     //     bankInfo: {},
    //     //     idNo: "",
    //     //     personName: "",
    //     //     phone: "",
    //     //     email: "",
    //     //     comment: "",
    //     //     // number: "",
    //     //     // name: "",
    //     //     // expiry: "",
    //     //     // cvc: "",
    //     // }
    // }





    render() {
        return (
            <div>
                <LoginControlUser></LoginControlUser>
                <Navbar isPublic='true' ></Navbar>

                <div className="row" style={{ "maxWidth": "750px", margin: "auto" }}>



                    <CardFormAk /*BankInfo={this.state.bankInfo} doPayment={this.doPayment} handleChange={this.handleChange}*/ ></CardFormAk>


                    {/* {this.state.bankInfo.Id === 4 ? <CardFormAk BankInfo={this.state.bankInfo} doPayment={this.doPayment} handleChange={this.handleChange} ></CardFormAk> : ""}
                    {this.state.bankInfo.Id === 1 ? <CardFormGaranti BankInfo={this.state.bankInfo}></CardFormGaranti> : ""} */}


                </div>
                <Footer></Footer>
                <Contract></Contract>
            </div >
        )
    }
}
